import React, { useRef } from 'react'
import { login, isAuthenticated } from '../utils/auth'
import { Pane, Table, Button, TextInputField } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import {
  navStyles,
  customListStyles,
  tableSectionStyles,
  generalStyles
} from '../styles/sharedComponentStyles'
import LoadingSpinner from '../components/LoadingSpinner'
import CreateRealm from '../components/CreateRealm'
import Can, { useCan } from '../components/Can'
import { SYSTEM_ADMIN } from '../utils/constants'
import { useHandleRealmList } from '../hooks/useHandleRealmList'
import _ from 'lodash'
import { navigate } from 'gatsby'

const useSharedStyles = createUseStyles({
  ...generalStyles,
  ...navStyles,
  ...customListStyles,
  ...tableSectionStyles,
  elementSelector: {
    composes: '$tableCell',
    cursor: 'pointer'
  },
  tableHeaderTitle: {
    textTransform: 'none'
  }
})

const RealmsPage = () => {
  const { verifyRoles } = useCan()
  const canSeeRealms = verifyRoles(SYSTEM_ADMIN)
  const realmsSectionRef = useRef(null)
  const sharedClasses = useSharedStyles()
  const {
    loading,
    realmsSet,
    filterValue,
    showCreateRealmDialog,
    handleCreateRealmCloseModal,
    handleCreateRealmOpenModal,
    handleRealmFilter,
    onRealmCreation
  } = useHandleRealmList({ realmsSectionRef, canSeeRealms })

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      <nav className={sharedClasses.nav}>
        <Pane className={sharedClasses.secondaryNav} flexWrap="wrap">
          <Pane flex={1}>
            <h1>Realms</h1>
          </Pane>

          <Pane
            className={sharedClasses.autoComplete}
            display="flex"
            marginRight="20px"
          >
            <TextInputField
              value={filterValue}
              label="Search for a realm name"
              onChange={e => handleRealmFilter(e.target.value)}
              style={{
                padding: '20px'
              }}
            />
          </Pane>
          <Can roles={SYSTEM_ADMIN}>
            <Button
              className={sharedClasses.buttonStyles}
              onClick={handleCreateRealmOpenModal}
            >
              Create New Realm
            </Button>
          </Can>
        </Pane>
      </nav>
      {!loading && canSeeRealms && realmsSet.length <= 0 && (
        <Pane
          display="flex"
          padding={30}
          justifyContent="center"
          alignItems="center"
        >
          <h2>Sorry, there are no realms.</h2>
        </Pane>
      )}
      {!loading && !canSeeRealms && (
        <Pane
          display="flex"
          padding={30}
          justifyContent="center"
          alignItems="center"
        >
          <h2>Sorry, you don't have permissions to see the list of realms.</h2>
        </Pane>
      )}
      <Pane ref={realmsSectionRef} className={sharedClasses.section}>
        {canSeeRealms &&
          realmsSet.map(realm => {
            if (!realm.dummyObj) {
              return (
                <Pane
                  key={realm.id}
                  data-testid={`realm-${_.kebabCase(realm.name)}`}
                >
                  <Table className={sharedClasses.table}>
                    <Table.Head className={sharedClasses.tableHeader}>
                      <Table.TextHeaderCell
                        className={sharedClasses.tableHeaderTitle}
                      >
                        <span>{realm.name}</span>
                      </Table.TextHeaderCell>
                      <Table.TextHeaderCell textAlign="right">
                        <Button
                          className={sharedClasses.buttonStyles}
                          marginRight={0}
                          onClick={() => navigate('/realm/' + realm.id)}
                        >
                          View Realm
                        </Button>
                      </Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body
                      height={100}
                      backgroundColor="#ffffff"
                      borderRadius="6px"
                    >
                      <Table.Row borderColor="#ffffff">
                        <Table.TextCell
                          className={sharedClasses.elementSelector}
                          onClick={() => navigate('/realm/' + realm.id)}
                        >
                          {realm.Sites_aggregate && (
                            <>{realm.Sites_aggregate.aggregate.count} Sites</>
                          )}
                        </Table.TextCell>
                        <Table.TextCell
                          className={sharedClasses.elementSelector}
                          onClick={() => navigate('/realm/' + realm.id)}
                        >
                          {realm.Realm_Users_aggregate && (
                            <>
                              {realm.Realm_Users_aggregate.aggregate.count}{' '}
                              Users
                            </>
                          )}
                        </Table.TextCell>
                        <Table.TextCell
                          className={sharedClasses.elementSelector}
                          onClick={() => navigate('/realm/' + realm.id)}
                        >
                          {realm.Studies_aggregate.aggregate.count} Studies
                        </Table.TextCell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Pane>
              )
            }
          })}
      </Pane>
      {showCreateRealmDialog && (
        <CreateRealm
          showModal={showCreateRealmDialog}
          handleCloseModal={handleCreateRealmCloseModal}
          onRealmCreated={onRealmCreation}
          realmNames={realmsSet.map(r => r.name)}
        />
      )}
    </>
  )
}

export default RealmsPage
