import gql from 'graphql-tag'

export const CREATE_REALM_MUTATION = gql`
  mutation CreateRealm(
    $name: String!
    $notes: String
    $usersIds: [Realm_User_insert_input!]!
  ) {
    insert_Realm(
      objects: { name: $name, notes: $notes, Realm_Users: { data: $usersIds } }
    ) {
      returning {
        id
        name
        notes
        createdAt
      }
    }
  }
`
