import React from 'react'
import { Pane, toaster } from 'evergreen-ui'
import Stepper from '../Stepper'
import CreateRealmDetails from './CreateRealmDetails'
import { useForm, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Modal from '../Modal'
import { useMutation } from '@apollo/client'
import { realmValidationSchema } from '../RealmDetails/realmFormSchemas'
import { CREATE_REALM_MUTATION } from '../../mutations/createRealm'
import { getProfile } from '../../utils/auth'
import { CREATE_REALM_ERROR_MESSAGE } from './createRealmUtils'

const CreateRealm = ({
  showModal,
  handleCloseModal,
  onRealmCreated,
  realmNames
}) => {
  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(realmValidationSchema(realmNames))
  })
  const user = getProfile()
  const { isValid } = useFormState({ control })
  const [createRealm, { loading }] = useMutation(CREATE_REALM_MUTATION, {
    update: (cache, { data: { insert_Realm } }) => {
      const newRealm = insert_Realm?.returning?.[0]
      if (newRealm) {
        cache.evict({
          // re triggers the realm query
          id: 'ROOT_QUERY',
          fieldName: 'Realm'
        })
        cache.evict({
          // re triggers the user query
          id: 'ROOT_QUERY',
          fieldName: 'User'
        })
      }
    },
    onCompleted: result => {
      const currentRealm = result?.insert_Realm?.returning?.[0]
      if (currentRealm) {
        if (onRealmCreated) {
          onRealmCreated(currentRealm)
        }
        toaster.success(
          `Success! Welcome to the new realm: ${currentRealm.name}.`
        )
        handleCloseModal()
      }
    },
    onError: _ => toaster.danger(CREATE_REALM_ERROR_MESSAGE)
  })

  const onSubmit = data => {
    data.usersIds = [{ user_id: user.sub }]
    createRealm({
      variables: {
        ...data
      }
    })
  }

  return (
    <Modal
      width={900}
      showDialog={showModal}
      handleDialogClose={handleCloseModal}
    >
      <Modal.Header title="Create Realm" handleCloseModal={handleCloseModal} />
      <Modal.Body>
        <Pane minHeight="570px">
          <Stepper
            steps={['Details']}
            onConfirm={handleSubmit(onSubmit)}
            onBack={handleCloseModal}
          >
            <Stepper.Step disableNext={!isValid} nextLoading={loading}>
              <CreateRealmDetails control={control} />
            </Stepper.Step>
          </Stepper>
        </Pane>
      </Modal.Body>
    </Modal>
  )
}

export default CreateRealm
