import { Pane } from 'evergreen-ui'
import React from 'react'
import { createUseStyles } from 'react-jss'
import {
  customListStyles,
  formStyles
} from '../../styles/sharedComponentStyles'
import { breakpoints } from '../../styles/theme'
import InputTextField from '../forms/InputTextField'
import TextAreaField from '../forms/TextAreaField'
const useSharedStyles = createUseStyles({
  ...formStyles,
  ...customListStyles,
  wrapper: {
    display: 'grid',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    overflowY: 'auto',
    '& label': {
      textAlign: 'left',
      marginLeft: '12px'
    }
  },
  form: {
    width: '100%'
  },
  fieldsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [`@media (max-width: ${breakpoints.sm})`]: {
      gridTemplateColumns: 'auto'
    }
  },
  fieldsLeft: {
    display: 'grid',
    flexBasis: '50%',
    gridTemplateRows: '50px 250px',
    rowGap: '40px',
    padding: '20px'
  },
  fieldsRight: {
    display: 'grid',
    flexBasis: '50%',
    rowGap: '40px',
    padding: '20px',
    gridTemplateRows: '300px'
  }
})

const CreateRealmDetails = ({ control }) => {
  const sharedClasses = useSharedStyles()
  return (
    <Pane className={sharedClasses.wrapper}>
      <Pane className={sharedClasses.fieldsWrapper}>
        <Pane className={sharedClasses.fieldsLeft}>
          <InputTextField
            control={control}
            name="name"
            defaultValue=''
            label="Name"
            required
            showErrorMessage
          />
        </Pane>
        <Pane className={sharedClasses.fieldsRight}>
          <TextAreaField
            control={control}
            name="notes"
            label="Notes"
            inputHeight={100}
            defaultValue=""
          />
        </Pane>
      </Pane>
    </Pane>
  )
}

export default CreateRealmDetails
